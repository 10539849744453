<template>
	<div>
		<div class="account-pages my-5 pt-5">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6 col-xl-5">
						<div class="card overflow-hidden">
							<div class="bg-primary">
								<div class="text-primary text-center p-4">
									<h5 class="text-white font-size-20" id="requestTitle">Reset Password</h5>
									<p class="text-white-50">Request a password reset link via email.</p>
									<router-link tag="a" to="/" class="logo logo-admin">
										<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
									</router-link>
								</div>
							</div>
							<div class="card-body p-4">
								<div class="pt-3 pl-3 pr-3">
									<b-alert
										v-if="isRequestError"
										show
										class="mt-4 text-center"
										variant="danger"
										id="errorAlert"
									>{{ requestError }}</b-alert>

									<b-alert
										v-else
										show
										class="mt-4 text-center"
										id="messageAlert"
									>{{ message }}</b-alert>

									<b-form @submit.prevent="requestReset" class="form-horizontal mt-4" id="resetForm">
										<div v-if="!requestSuccess">
											<b-form-group id="input-group-1" label="Email" label-for="email">
												<b-form-input
													id="email"
													v-model="email"
													type="email"
													placeholder="Enter email"
													:disabled="isRequesting"
													:class="{ 'is-invalid': $v.email.$error }"
													@input="$v.email.$touch"
													v-focus
												></b-form-input>
												<div class="invalid-feedback" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
												<div class="invalid-feedback" v-else-if="!$v.email.email && $v.email.$dirty">This is not a valid email</div>
											</b-form-group>

											<div class="form-group row">
												<div class="col-sm-12 text-center">
													<b-button variant="primary" class="w-md" id="resetBtn" type="submit" :disabled="isRequesting">
														<span v-if="!isRequesting">Request Reset Link</span>
														<i v-else class="fas fa-spin fa-circle-notch"></i>
													</b-button>
												</div>
											</div>
										</div>

										<div class="form-group mt-2 mb-0 row">
											<div class="col-12 text-center">
												<router-link tag="a" to="/login">
													<i class="mdi mdi-login"></i> Login
												</router-link>
											</div>
										</div>
									</b-form>
								</div>
							</div>
						</div>
						<div class="mt-5 text-center">
							<p class="mb-0">
								© {{new Date().getFullYear()}} instaGiv. All Rights Reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

/**
 * Request password reset component
 */
export default {
	name: 'RequestReset',
	page: {
		title: "Request Password Reset"
	},
	data() {
		return {
			email: '',
			isRequesting: false,
			isRequestError: false,
			requestError: null,
			requestSuccess: null,
			message: 'Enter your email below to get a password reset link.',
		}
	},
	methods: {
		requestReset() {
			this.requestError = null;
			this.isRequesting = true;
			this.isError = false;

			this.$v.$touch()

			if (this.$v.$invalid) {
				this.isRequesting = false;
				this.requestError = 'Please make sure you have entered your email correctly.'
				this.isRequestError = true
				return
			}

			var data = { email: this.email }

			this.axios.post('charity-user/request-reset-link', {
					...data
				})
				.then(() => {
					this.isRequesting = false;
					this.isRequestError = false;
					this.requestSuccess = true;
					this.message = 'Thanks! If the email address matches an instaGiv account you will be sent a password reset link.'
				})
				.catch((error) => {
					this.isRequesting = false;
					this.requestError = (error.response && error.response.data.error_text) ? error.response.data.error_text : 'An unexpected error occurred. If the problem persists, please contact support.'
					this.isRequestError = true;
					// eslint-disable-next-line
					console.error('Error:', error);
				})
		}
	},
	validations: {
		email: { required, email }
	},	
	directives: {
		focus: {
			inserted: (el) => {
				el.focus()
			}
		}
	}
};
</script>